<template>
  <div class="site-burger" @click="emit('open', !props.open)">
    <div class="site-burger__bars" :class="{
      change: props.open
    }">
      <div class="site-burger__bars__bar bar1"></div>
      <div class="site-burger__bars__bar bar2"></div>
      <div class="site-burger__bars__bar bar3"></div>
    </div>
  </div>
</template>

<script setup>
  const emit = defineEmits(['open'])

  const props = defineProps({
    open: {
      type: Boolean,
      required: true
    }
  })
</script>

<style lang="scss">
  @use './index.scss';
</style>